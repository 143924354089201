:root {
	--of-ph-color: transparent;
}

.order-form-container ::-webkit-input-placeholder {
	/* WebKit browsers */
	color: var(--of-ph-color);
}
.order-form-container :-moz-placeholder {
	/* Mozilla Firefox 4 to 18 */
	color: var(--of-ph-color);
}
.order-form-container ::-moz-placeholder {
	/* Mozilla Firefox 19+ */
	color: var(--of-ph-color);
}
.order-form-container :-ms-input-placeholder {
	/* Internet Explorer 10+ */
	color: var(--of-ph-color);
}
.order-form-container input::placeholder {
	color: var(--of-ph-color);
}

.order-form-container select::placeholder {
	color: var(--of-ph-color);
}

.order-form-container {
	text-align: left;
}

.order-form-container .contact-information {
	margin-top: 10px;
}

.btn-submit {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.btn-submit .spinner-border {
	margin-right: 10px;
	width: 24px;
	height: 24px;
}

.row .col-sm-12 {
	margin-bottom: 5px;
}

.row .btn.col-sm-12 {
	margin-bottom: 0;
}

.row.expiration-date {
	margin-bottom: 5px;
}

.row.cards-notice {
	padding-top: 10px;
	font-size: 14px;
}

@media screen and (max-width: 575px) {
	.order-form-container .contact-information {
		/* margin-top: 10px; */
	}

	.row.expiration-date {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
}
