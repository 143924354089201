.App {
	text-align: center;
	/* padding-top: 30px; */
	/* padding-bottom: 30px; */
}

@media screen and (max-width: 767px) {
	.App {
		/* padding-top: 10px; */
		/* padding-bottom: 10px; */
	}
}
